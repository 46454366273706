<template>
  <div class="drawer" :class="{ 'is-compact': isDrawerCompact }">
    <div class="header">
      <client-only>
        <atomic-link class="drawer__logo" href="/">
          <atomic-image class="img" src="/img/logo-short.svg" />
        </atomic-link>
      </client-only>

      <div class="drawer__close">
        <atomic-icon id="close" />
      </div>
    </div>

    <div class="content">
      <client-only>
        <atomic-link v-if="isLoggedIn && loyaltyEnabled" href="/loyalty" class="drawer__loyalty">
          <loyalty-level-logo />
          <loyalty-progress short-version />
        </atomic-link>
      </client-only>

      <nav-list v-bind="getContent(layoutData, defaultLocaleLayoutData, 'siteSidebar.categoriesMenu')" />
      <atomic-divider />

      <nav-list v-bind="getContent(layoutData, defaultLocaleLayoutData, 'siteSidebar.mainMenu')" />

      <div class="drawer__footer-menu">
        <atomic-select-lang />

        <client-only>
          <div v-if="projectHasFreshchat" class="nav-list">
            <div class="item" :class="{ 'chat-indicator': newMessages }">
              <div class="link" @click="openChat">
                <atomic-icon id="live-support" />
                <div class="text">
                  {{ getContent(layoutData, defaultLocaleLayoutData, 'siteSidebar.footerMenu.chatLabel') }}
                </div>
              </div>
            </div>
          </div>
        </client-only>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia';

  const { layoutData, defaultLocaleLayoutData } = useGlobalStore();
  const { getContent } = useProjectMethods();

  const layoutStore = useLayoutStore();
  const { openModal } = useModalStore();
  const { isDrawerCompact } = storeToRefs(layoutStore);

  const profileStore = useProfileStore();
  const { isLoggedIn } = storeToRefs(profileStore);

  const freshchatStore = useFreshchatStore();
  const { newMessages, projectHasFreshchat } = storeToRefs(freshchatStore);

  const {
    public: { freshchatParams, loyaltyEnabled },
  } = useRuntimeConfig();
  const openChat = () => {
    if (!freshchatParams?.guestAvailable && !isLoggedIn.value) openModal('sign-up');
    else window.fcWidget?.open();
  };
</script>

<style src="~/assets/styles/components/layout/drawer.scss" lang="scss" />
