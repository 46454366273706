<template>
  <div v-if="showBlock" class="group-recently">
    <atomic-icon
      v-if="getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'cardsGroup.recentlyPlayed')"
      :id="getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'cardsGroup.recentlyPlayed.icon')"
    />

    <h2 class="title">
      {{ getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'cardsGroup.recentlyPlayed.label') }}
    </h2>

    <button-base v-if="props.showAllBtn" class="btn-show-all" type="ghost" size="sm" @click="openGames">
      {{ getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'cardsGroup.moreButton') }}
    </button-base>

    <button-arrows
      v-if="showArrowButtons"
      :prev-disabled="prevDisabled"
      :next-disabled="nextDisabled"
      @click-action="clickAction"
    />

    <div
      ref="scrollContainer"
      class="items"
      :class="{ 'disabled-scroll-block': !games.length }"
      @scroll="scrollHandler"
    >
      <template v-if="games.length">
        <card-base v-for="(game, gameIndex) in games" :key="gameIndex" :game-info="game" square />
      </template>

      <template v-else>
        <Skeletor v-for="n in 18" :key="n" class="card-base" as="div" />
      </template>

      <div ref="loadMore" class="load-more" @inview="moreGames" />
    </div>
  </div>
</template>

<script setup lang="ts">
  import { Skeletor } from 'vue-skeletor';
  import { storeToRefs } from 'pinia';
  import type { IGame, IPaginationMeta } from '~/types';

  const props = defineProps<{
    showAllBtn?: boolean;
    showArrows?: boolean;
  }>();

  const globalStore = useGlobalStore();
  const { globalComponentsContent, defaultLocaleGlobalComponentsContent, isMobile, headerCountry } =
    storeToRefs(globalStore);
  const { getContent } = useProjectMethods();
  const { getRecentlyPlayed } = useCoreGamesApi();
  const profileStore = useProfileStore();
  const { profile } = storeToRefs(profileStore);

  const scrollContainer = ref();
  const prevDisabled = ref<boolean>(true);
  const nextDisabled = ref<boolean>(true);
  const showArrowButtons = ref<boolean>(props.showArrows);
  const games = ref<IGame[]>([]);
  const pageMeta = ref<IPaginationMeta>();

  const loadMore = ref();
  const { initObserver } = useProjectMethods();
  const observerLoadMore = ref();

  const emit = defineEmits(['show-recently']);

  const defaultRequestParams = {
    perPage: 18,
    platform: isMobile.value ? 1 : 2,
    countryCode: profile.value?.country || headerCountry.value || 'UA',
  };

  const clickAction = (direction: string): void => {
    const { offsetWidth, scrollWidth, scrollLeft } = scrollContainer.value;
    const widthToEnd = scrollWidth - (scrollLeft + offsetWidth);
    const scrollLeftValue = widthToEnd < offsetWidth ? widthToEnd : offsetWidth;
    const scrollRightValue = scrollLeft < offsetWidth ? scrollLeft : offsetWidth;
    scrollContainer.value.scrollBy({
      left: direction === 'next' ? scrollLeftValue : -scrollRightValue,
      behavior: 'smooth',
    });
  };

  const moreGames = async (): Promise<void> => {
    if (pageMeta.value?.page === pageMeta.value?.totalPages) return;

    const gamesResponse = await getRecentlyPlayed({
      ...defaultRequestParams,
      page: pageMeta.value ? pageMeta.value.page + 1 : 1,
    });
    games.value = games.value.concat(gamesResponse);
  };

  const scrollHandler = (): void => {
    if (!scrollContainer.value) return;
    const { scrollLeft, offsetWidth, scrollWidth } = scrollContainer.value;
    prevDisabled.value = scrollLeft === 0;
    nextDisabled.value = scrollWidth < scrollLeft + offsetWidth + 20 && scrollWidth > scrollLeft + offsetWidth - 20;
  };

  const loadingRecently = ref<boolean>(true);
  const showBlock = ref<boolean>(true);

  onMounted(async () => {
    observerLoadMore.value = initObserver({
      settings: { root: scrollContainer.value, rootMargin: '90%', threshold: 0 },
    });
    observerLoadMore.value.observe(loadMore.value);

    const gamesResponseData = await getRecentlyPlayed(defaultRequestParams);
    if (!gamesResponseData.length) return (showBlock.value = false);
    loadingRecently.value = false;
    games.value = gamesResponseData;

    if (games.value) emit('show-recently', true);

    await nextTick(() => {
      scrollHandler();
    });
  });

  const { localizePath } = useProjectMethods();
  const openGames = (): void => {
    const router = useRouter();
    router.push(localizePath(`/recently-played`));
  };

  onBeforeUnmount(() => {
    if (loadMore.value && observerLoadMore.value) {
      observerLoadMore.value.unobserve(loadMore.value);
    }
  });
</script>

<style src="~/assets/styles/components/group/recently.scss" lang="scss" />
