<template>
  <div>
    <main-slider :style="sliderVisibilityStyle" />

    <nav-category
      :display-recently-item="showRecentlyItem"
      @click-category="changeCategory"
      @openProviders="openProviders"
    />

    <client-only>
      <group-favorites v-if="isLoggedIn" show-arrows show-all-btn />
      <group-recently v-if="isLoggedIn" show-arrows show-all-btn @show-recently="showRecentlyItem = true" />
    </client-only>

    <group-games
      v-for="category in mainCategoriesList.slice(0, 3)"
      :key="category.id"
      showAllBtn
      showArrows
      :category="category"
    />

    <group-providers showArrows showAllBtn />

    <group-games
      v-for="category in mainCategoriesList.slice(3, 4)"
      :key="category.id"
      showAllBtn
      showArrows
      :category="category"
    />

    <group-winners v-if="globalComponentsContent?.cardsGroup?.latestWinners?.display" showArrows />

    <group-games
      v-for="category in mainCategoriesList.slice(4)"
      :key="category.id"
      showAllBtn
      showArrows
      :category="category"
    />

    <atomic-seo-text
      v-if="pageContent?.currentLocaleData?.pageMeta?.seoText"
      v-bind="pageContent.currentLocaleData.pageMeta.seoText"
    />
  </div>
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia';
  import type { CIHomePage, ICollection } from '~/types';

  const globalStore = useGlobalStore();
  const profileStore = useProfileStore();
  const { localizePath, getContent } = useProjectMethods();
  const { isLoggedIn } = storeToRefs(profileStore);
  const { globalComponentsContent, defaultLocaleGlobalComponentsContent } = globalStore;
  const showRecentlyItem = ref<boolean>(false);
  const { openModal, closeModal } = useModalStore();

  const contentParams = {
    contentKey: 'homePageContent',
    contentRoute: ['pages', 'home'],
    isPage: true,
  };
  const { getContentData } = useContentLogic<CIHomePage>(contentParams);
  const { data: pageContent } = await useLazyAsyncData(contentParams.contentKey, () => getContentData());

  const { getCollectionsList, getProviderList } = useGamesStore();
  const { data: gameCollections } = await useLazyAsyncData(() => getCollectionsList(), { server: false });
  const mainCategoriesList = computed(() => {
    return (
      gameCollections.value?.reduce((categoriesArr: ICollection[], currentCategory) => {
        return currentCategory.isHidden ? categoriesArr : [...categoriesArr, currentCategory];
      }, []) || []
    );
  });

  const router = useRouter();

  const changeCategory = async (categoryIdentity: string): Promise<void> => {
    await router.push(localizePath(`/categories/${categoryIdentity}`));
  };

  const selectedProviders = ref<string[]>([]);
  const sliderVisibilityHidden = ref<boolean>(false);

  const sliderVisibilityStyle = computed(() => {
    return { visibility: !sliderVisibilityHidden.value ? 'visible' : 'hidden' };
  });

  const changeProvider = async (newSelectedProviders: string[]) => {
    selectedProviders.value = newSelectedProviders;
    sliderVisibilityHidden.value = true;

    const gameProviders = await getProviderList();
    const providersIdentity = gameProviders
      .filter(provider => newSelectedProviders.includes(provider.id))
      .map(provider => provider.identity);

    const defaultCategory = getContent(
      globalComponentsContent,
      defaultLocaleGlobalComponentsContent,
      'providersSettings.defaultCategory'
    );

    await closeModal('providers');
    await router.push({
      path: localizePath(defaultCategory ? `/categories/${defaultCategory}` : '/categories'),
      query: {
        provider: providersIdentity,
      },
    });
  };

  const openProviders = () => {
    openModal('providers', { props: { selected: selectedProviders, onSelect: changeProvider } });
  };
</script>
