<template>
  <vue-final-modal
    class="modal-categories"
    :overlay-transition="{ mode: 'in-out', duration: 250 }"
    :content-transition="{ mode: 'in-out', duration: 250 }"
  >
    <div class="modal-categories__container">
      <div class="modal-categories__header">
        <button-modal-close @close="closeModal('categories')" />
        <div class="modal-categories__header-title">
          {{ getContent(props.currentLocaleData, props.defaultLocaleData, 'title') }}
        </div>
      </div>

      <div class="modal-categories__content">
        <div
          v-for="({ id, identity, name }, index) in categories"
          :key="id"
          class="modal-categories__item"
          :data-index="index"
          :class="{
            'is-active': route.params?.categoryIdentity === identity,
            'is-no-icon': !gameCategoriesObj[identity]?.icon,
          }"
          @click="navigateCategory(identity)"
        >
          <atomic-icon :id="gameCategoriesObj[identity]?.icon" />
          <span>{{ gameCategoriesObj[identity]?.label || name }}</span>
        </div>
      </div>
    </div>
  </vue-final-modal>
</template>

<script setup lang="ts">
  import { VueFinalModal } from 'vue-final-modal';
  import type { CIModalsContent, ICollection } from '~/types';

  const props = defineProps<{
    currentLocaleData: Maybe<CIModalsContent['categories']>;
    defaultLocaleData: Maybe<CIModalsContent['categories']>;
  }>();

  const { closeModal } = useModalStore();
  const { getContent, localizePath } = useProjectMethods();
  const { gameCategoriesObj } = useGlobalStore();
  const categories = ref<ICollection[]>([]);
  const route = useRoute();
  const router = useRouter();

  const navigateCategory = async (categoryIdentity: string): Promise<void> => {
    if (route.params?.categoryIdentity === categoryIdentity) return;
    await router.push(localizePath(`/categories/${categoryIdentity}`));
    await closeModal('categories');
  };

  onMounted(async () => {
    const { getCollectionsList } = useGamesStore();
    const gameCollections = await getCollectionsList();
    categories.value = gameCollections.filter(collection => !collection.isHidden);
  });
</script>

<style src="~/assets/styles/components/modal/categories.scss" lang="scss" />
