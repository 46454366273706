<template>
  <div class="nav-list">
    <div v-for="(listItem, index) in currentList" :key="index" class="item">
      <atomic-link
        class="link"
        :href="listItem.url"
        :target-blank="listItem?.targetBlank"
        :class="{ 'is-active': route.fullPath === localizePath(listItem.url) }"
      >
        <atomic-icon :id="listItem?.icon ? listItem?.icon : 'dot-md'" />
        <div class="text">{{ listItem.label }}</div>

        <span v-if="listItem.url === '/bonuses' && bonusesCount" class="counter">
          {{ bonusesCount > 99 ? '99+' : bonusesCount }}
        </span>
      </atomic-link>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { CINavigationItem } from '~/types';
  import { storeToRefs } from 'pinia';

  const props = defineProps<{
    items: CINavigationItem[];
  }>();

  const route = useRoute();
  const { localizePath } = useProjectMethods();
  const profileStore = useProfileStore();
  const { isLoggedIn } = storeToRefs(profileStore);

  const bonusStore = useBonusStore();
  const { bonusesCount } = storeToRefs(bonusStore);

  const authLinks = ['/bonuses'];
  const currentList = ref<CINavigationItem[]>(props.items.filter(item => !authLinks.includes(item.url)));

  const nuxtApp = useNuxtApp();
  watch(isLoggedIn, newValue => {
    if (import.meta.server || nuxtApp.isHydrating) return;
    currentList.value = props.items.filter(item => newValue || !authLinks.includes(item.url));
  });

  onMounted(() => {
    if (profileStore.isLoggedIn) {
      currentList.value = props.items.filter(item => isLoggedIn.value || !authLinks.includes(item.url));
    }
  });
</script>

<style src="~/assets/styles/components/nav/list.scss" lang="scss" />
