
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace('')
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46slug_93uVjAiQKNcYMeta } from "/builds/platform/customer-frontends/winlion/pages/[...slug].vue?macro=true";
import { default as autologinHamLQPEWF1Meta } from "/builds/platform/customer-frontends/winlion/pages/auth/autologin.vue?macro=true";
import { default as callbackU6H85HSu1tMeta } from "/builds/platform/customer-frontends/winlion/pages/auth/callback.vue?macro=true";
import { default as bettingUm0sPiym5fMeta } from "/builds/platform/customer-frontends/winlion/pages/betting.vue?macro=true";
import { default as _91pageIdentity_93ZHzHXAGqPeMeta } from "/builds/platform/customer-frontends/winlion/pages/bonus/[pageIdentity].vue?macro=true";
import { default as bonusesuXogU7ECK2Meta } from "/builds/platform/customer-frontends/winlion/pages/bonuses.vue?macro=true";
import { default as _91categoryIdentity_93rxFdVfmPAzMeta } from "/builds/platform/customer-frontends/winlion/pages/categories/[categoryIdentity].vue?macro=true";
import { default as categorieseQ4Fi7DlxZMeta } from "/builds/platform/customer-frontends/winlion/pages/categories.vue?macro=true";
import { default as contactTJaOYk9SyfMeta } from "/builds/platform/customer-frontends/winlion/pages/contact.vue?macro=true";
import { default as favorites_46clientlfal5o7O9bMeta } from "/builds/platform/customer-frontends/winlion/pages/favorites.client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/builds/platform/customer-frontends/winlion/node_modules/nuxt/dist/components/runtime/client-component.js").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as _91id_93DO5g53ZbA3Meta } from "/builds/platform/customer-frontends/winlion/pages/games/[id].vue?macro=true";
import { default as indexx1RWZKiym3Meta } from "/builds/platform/customer-frontends/winlion/pages/index.vue?macro=true";
import { default as loyaltyg5z1i32XKzMeta } from "/builds/platform/customer-frontends/winlion/pages/loyalty.vue?macro=true";
import { default as documentsqubakRomQzMeta } from "/builds/platform/customer-frontends/winlion/pages/profile/documents.vue?macro=true";
import { default as historyfCpgmdgPzuMeta } from "/builds/platform/customer-frontends/winlion/pages/profile/history.vue?macro=true";
import { default as infoxxOrpzAtA3Meta } from "/builds/platform/customer-frontends/winlion/pages/profile/info.vue?macro=true";
import { default as limitsok7iGAahNGMeta } from "/builds/platform/customer-frontends/winlion/pages/profile/limits.vue?macro=true";
import { default as notifications17sHJVzNtqMeta } from "/builds/platform/customer-frontends/winlion/pages/profile/notifications.vue?macro=true";
import { default as securitymto7GPahnfMeta } from "/builds/platform/customer-frontends/winlion/pages/profile/security.vue?macro=true";
import { default as verificationtoutRxmhDVMeta } from "/builds/platform/customer-frontends/winlion/pages/profile/verification.vue?macro=true";
import { default as walletTR2SKLkWFSMeta } from "/builds/platform/customer-frontends/winlion/pages/profile/wallet.vue?macro=true";
import { default as profile_46clientIFkufzhE8pMeta } from "/builds/platform/customer-frontends/winlion/pages/profile.client.vue?macro=true";
import { default as providers0typOdP0ctMeta } from "/builds/platform/customer-frontends/winlion/pages/providers.vue?macro=true";
import { default as _91pageIdentity_934L8iW18a8kMeta } from "/builds/platform/customer-frontends/winlion/pages/questions/[pageIdentity].vue?macro=true";
import { default as questionsFxA7khpAxNMeta } from "/builds/platform/customer-frontends/winlion/pages/questions.vue?macro=true";
import { default as recently_45played_46clientgKznVvGUpuMeta } from "/builds/platform/customer-frontends/winlion/pages/recently-played.client.vue?macro=true";
import { default as _91pageIdentity_934wZYYXTiJTMeta } from "/builds/platform/customer-frontends/winlion/pages/static/[pageIdentity].vue?macro=true";
import { default as _91identity_93qO7i2nggkLMeta } from "/builds/platform/customer-frontends/winlion/pages/tournaments/[identity].vue?macro=true";
import { default as indexy4JGTAX5lEMeta } from "/builds/platform/customer-frontends/winlion/pages/tournaments/index.vue?macro=true";
import { default as welcome_45packagekTfwhRdXr7Meta } from "/builds/platform/customer-frontends/winlion/pages/welcome-package.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/builds/platform/customer-frontends/winlion/pages/[...slug].vue")
  },
  {
    name: "auth-autologin",
    path: "/auth/autologin",
    component: () => import("/builds/platform/customer-frontends/winlion/pages/auth/autologin.vue")
  },
  {
    name: "auth-callback",
    path: "/auth/callback",
    component: () => import("/builds/platform/customer-frontends/winlion/pages/auth/callback.vue")
  },
  {
    name: "betting",
    path: "/betting",
    component: () => import("/builds/platform/customer-frontends/winlion/pages/betting.vue")
  },
  {
    name: "bonus-pageIdentity",
    path: "/bonus/:pageIdentity()",
    component: () => import("/builds/platform/customer-frontends/winlion/pages/bonus/[pageIdentity].vue")
  },
  {
    name: "bonuses",
    path: "/bonuses",
    meta: bonusesuXogU7ECK2Meta || {},
    component: () => import("/builds/platform/customer-frontends/winlion/pages/bonuses.vue")
  },
  {
    name: "categories",
    path: "/categories",
    component: () => import("/builds/platform/customer-frontends/winlion/pages/categories.vue"),
    children: [
  {
    name: "categories-categoryIdentity",
    path: ":categoryIdentity()",
    component: () => import("/builds/platform/customer-frontends/winlion/pages/categories/[categoryIdentity].vue")
  }
]
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/builds/platform/customer-frontends/winlion/pages/contact.vue")
  },
  {
    name: "favorites",
    path: "/favorites",
    meta: favorites_46clientlfal5o7O9bMeta || {},
    component: () => createClientPage(() => import("/builds/platform/customer-frontends/winlion/pages/favorites.client.vue"))
  },
  {
    name: "games-id",
    path: "/games/:id()",
    component: () => import("/builds/platform/customer-frontends/winlion/pages/games/[id].vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/builds/platform/customer-frontends/winlion/pages/index.vue")
  },
  {
    name: "loyalty",
    path: "/loyalty",
    component: () => import("/builds/platform/customer-frontends/winlion/pages/loyalty.vue")
  },
  {
    name: "profile",
    path: "/profile",
    meta: profile_46clientIFkufzhE8pMeta || {},
    component: () => createClientPage(() => import("/builds/platform/customer-frontends/winlion/pages/profile.client.vue")),
    children: [
  {
    name: "profile-documents",
    path: "documents",
    component: () => import("/builds/platform/customer-frontends/winlion/pages/profile/documents.vue")
  },
  {
    name: "profile-history",
    path: "history",
    component: () => import("/builds/platform/customer-frontends/winlion/pages/profile/history.vue")
  },
  {
    name: "profile-info",
    path: "info",
    component: () => import("/builds/platform/customer-frontends/winlion/pages/profile/info.vue")
  },
  {
    name: "profile-limits",
    path: "limits",
    component: () => import("/builds/platform/customer-frontends/winlion/pages/profile/limits.vue")
  },
  {
    name: "profile-notifications",
    path: "notifications",
    component: () => import("/builds/platform/customer-frontends/winlion/pages/profile/notifications.vue")
  },
  {
    name: "profile-security",
    path: "security",
    component: () => import("/builds/platform/customer-frontends/winlion/pages/profile/security.vue")
  },
  {
    name: "profile-verification",
    path: "verification",
    component: () => import("/builds/platform/customer-frontends/winlion/pages/profile/verification.vue")
  },
  {
    name: "profile-wallet",
    path: "wallet",
    component: () => import("/builds/platform/customer-frontends/winlion/pages/profile/wallet.vue")
  }
]
  },
  {
    name: "providers",
    path: "/providers",
    component: () => import("/builds/platform/customer-frontends/winlion/pages/providers.vue")
  },
  {
    name: "questions",
    path: "/questions",
    meta: questionsFxA7khpAxNMeta || {},
    component: () => import("/builds/platform/customer-frontends/winlion/pages/questions.vue"),
    children: [
  {
    name: "questions-pageIdentity",
    path: ":pageIdentity()",
    component: () => import("/builds/platform/customer-frontends/winlion/pages/questions/[pageIdentity].vue")
  }
]
  },
  {
    name: "recently-played",
    path: "/recently-played",
    meta: recently_45played_46clientgKznVvGUpuMeta || {},
    component: () => createClientPage(() => import("/builds/platform/customer-frontends/winlion/pages/recently-played.client.vue"))
  },
  {
    name: "static-pageIdentity",
    path: "/static/:pageIdentity()",
    component: () => import("/builds/platform/customer-frontends/winlion/pages/static/[pageIdentity].vue")
  },
  {
    name: "tournaments-identity",
    path: "/tournaments/:identity()",
    component: () => import("/builds/platform/customer-frontends/winlion/pages/tournaments/[identity].vue")
  },
  {
    name: "tournaments",
    path: "/tournaments",
    component: () => import("/builds/platform/customer-frontends/winlion/pages/tournaments/index.vue")
  },
  {
    name: "welcome-package",
    path: "/welcome-package",
    component: () => import("/builds/platform/customer-frontends/winlion/pages/welcome-package.vue")
  }
]