<template>
  <div v-if="favoriteGames.length" class="group-favorites">
    <atomic-icon
      v-if="getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'cardsGroup.favorites')"
      :id="getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'cardsGroup.favorites.icon')"
    />

    <h2 class="title">
      {{ getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'cardsGroup.favorites.label') }}
    </h2>

    <button-base v-if="props.showAllBtn" class="btn-show-all" type="ghost" size="sm" @click="openGames">
      {{ getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'cardsGroup.moreButton') }}
    </button-base>

    <button-arrows
      v-if="showArrowButtons"
      :prev-disabled="prevDisabled"
      :next-disabled="nextDisabled"
      @click-action="clickAction"
    />

    <div
      ref="scrollContainer"
      class="items"
      :class="{ 'disabled-scroll-block': !favoriteGames.length }"
      @scroll="scrollHandler"
    >
      <template v-if="favoriteGames.length">
        <card-base v-for="(game, gameIndex) in favoriteGames" :key="gameIndex" :game-info="game" square />
      </template>

      <template v-else>
        <Skeletor v-for="n in 18" :key="n" class="card-base" as="div" />
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { Skeletor } from 'vue-skeletor';
  import { storeToRefs } from 'pinia';

  const props = defineProps<{
    showArrows?: boolean;
    showAllBtn?: boolean;
  }>();

  const globalStore = useGlobalStore();
  const { globalComponentsContent, defaultLocaleGlobalComponentsContent } = storeToRefs(globalStore);
  const { getContent } = useProjectMethods();
  const gameStore = useGamesStore();
  const { favoriteGames } = storeToRefs(gameStore);

  const scrollContainer = ref();
  const prevDisabled = ref<boolean>(true);
  const nextDisabled = ref<boolean>(true);
  const showArrowButtons = ref<boolean>(props.showArrows);

  const scrollHandler = (): void => {
    if (!scrollContainer.value) return;
    const { scrollLeft, offsetWidth, scrollWidth } = scrollContainer.value;
    prevDisabled.value = scrollLeft === 0;
    nextDisabled.value = scrollWidth < scrollLeft + offsetWidth + 20 && scrollWidth > scrollLeft + offsetWidth - 20;
  };

  const clickAction = (direction: string): void => {
    const { offsetWidth, scrollWidth, scrollLeft } = scrollContainer.value;
    const widthToEnd = scrollWidth - (scrollLeft + offsetWidth);
    const scrollLeftValue = widthToEnd < offsetWidth ? widthToEnd : offsetWidth;
    const scrollRightValue = scrollLeft < offsetWidth ? scrollLeft : offsetWidth;
    scrollContainer.value.scrollBy({
      left: direction === 'next' ? scrollLeftValue : -scrollRightValue,
      behavior: 'smooth',
    });
  };

  const { localizePath } = useProjectMethods();
  const openGames = (): void => {
    const router = useRouter();
    router.push(localizePath('/favorites'));
  };

  onMounted(() => {
    nextTick(() => {
      scrollHandler();
    });
  });

  watch(
    () => favoriteGames.value,
    () => {
      nextTick(() => {
        scrollHandler();
      });
    }
  );
</script>

<style src="~/assets/styles/components/group/favorites.scss" lang="scss" />
